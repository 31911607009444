<!-- 选择居民弹窗-->
<template>
  <div>
    <el-dialog
      title="选择居民"
      :visible.sync="visible"
      width="40%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">人员列表</div>
        </div>
      </div>
      <!-- 基本信息 -->
      <a-table :data-source="data" :columns="columns" :row-selection="rowSelection">
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
      </a-table>
      <!-- <ax-table
        ref="tableBox"
        :columns="columns"
        :show-search="true"
        :searchForm="searchForm"
        :searchActions="searchActions"
        :dynamicSlotNames="['type']"
        :showToolBar="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :dataSourceApi="dataSourceApis"
        :dataSourceParams="dataSourceParams"
        @add="transmissionId"
      >
        <a-select
          slot="type"
          :default-value="typeData[0]"
          placeholder="请选择人员类型"
          @change="typeChange"
        >
          <a-select-option v-for="(type,index) in typeData" :key="index" :value="type.value">
            {{ type.label }}
          </a-select-option>
        </a-select>
      </ax-table> -->
    </el-dialog>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "./api";
const searchForm = [
  {
    label: "人员类型",
    type: "",
    model: "type",
    options: { placeholder: "输入关键字进行搜索" },
    formItem: {},
    col: { span: 12 },
  },
  {
    label: "关键字",
    type: "inputSearch",
    model: "searchText",
    options: { placeholder: "输入关键字进行搜索" },
    formItem: {},
    col: { span: 12 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      api,
      visible: false,
      columns: this.$ax.tools.initColumn([
        {
          title: "姓名",
          dataIndex: "residentName",
          ellipsis: true,
          width: 100,
        },
        {
          title: "身份证号",
          dataIndex: "identityCard",
          ellipsis: true,
          width: 150,
        },
        {
          title: "现住区域",
          dataIndex: "currentArea",
          ellipsis: true,
          width: 100,
        },
      ]),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
        { name: "add", text: "保存" },
      ],
      selectedRowKeys: [],
      dataSourceApis: api.womanList,
      dataSourceParams: {},
      typeData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.getDictionsary();
      this.$nextTick(() => {
        this.selectedRowKeys = [];
      });
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
    },
    // 人员分类
    typeChange(e) {
      console.log(typeof e);
      switch (e) {
        case "1":
          this.dataSourceApis = api.womanList;
          this.$refs.tableBox.getDataSource();
          break;
        case "2":
          this.dataSourceApis = api.childrenList;
          this.$refs.tableBox.getDataSource();
          break;
        case "3":
          this.dataSourceApis = api.elderlyList;
          break;
        case "4":
          this.dataSourceApis = api.veteranList;
          break;
        case "5":
          this.dataSourceApis = api.psychopathList;
          break;
        case "6":
          this.dataSourceApis = api.emancipistlist;
          break;
        case "7":
          this.dataSourceApis = api.rectificationList;
          break;
        case "8":
          this.dataSourceApis = api.drugList;
          break;
        case "9":
          this.dataSourceApis = api.cultList;
          break;
        case "10":
          this.dataSourceApis = api.minorsList;
          break;
        case "11":
          this.dataSourceApis = api.petitionList;
          break;
        default:
          this.dataSourceApis = api.womanList;
          break;
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
      console.log(selectedRowKeys, "95");
      this.selectedRowKeys = selectedRowKeys;
    },
    // 获取字典
    async getDictionsary() {
      // 人员类别
      await api.dictData({ dicKind: "resident_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.typeData = options;
        //   this.$refs.form1.setFormItemProp("guardianshipFee", {
        //     options: { options, allowClear: true, placeholder: "请选择" },
        //   });
      });
    },
    // 保存
    transmissionId() {
      if (this.selectedRowKeys.length == "0") {
        this.visible = true;
        this.$message.warning('请选择居民')
      } else {
        this.visible = false;
        this.$emit("receive", this.selectedRowKeys);
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}
</style>